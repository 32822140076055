var CompensationForm = function () {
    var _this = this;
    this.form = document.getElementById('compensation-form');
    this.planSelector = null;
    this.error = false;
    this.errors = [];
    this.total = 0;
    this.postUrl = this.form.dataset.url;
    this.step = (document.getElementById('current-step') ? parseFloat(document.getElementById('current-step').value) : '');
    this.planData = (document.getElementById('plan-data') ? JSON.parse(document.getElementById('plan-data').value) : '');
    this.monthlyCost = (VuriaUtil.elementExists('#monthly-cost') ? parseFloat(document.getElementById('monthly-cost').value) : 25);
    this.monthlyFee = (VuriaUtil.elementExists('#monthly-fee') ? parseFloat(document.getElementById('monthly-fee').value) : 25);
    this.selectedPlan = (this.planData ? this.planData.selected_plan : '');
    this.initialPaymentDropdown = null;
    this.previousPlan = null;
    this.submitButton = this.form.querySelector('#submit');
    this.select2Options = {
        minimumResultsForSearch: -1,
    };
    this.stripe = null;
    this.stripePaymentElements = null;
    // Coupon stuff will be loaded later and only for steps with coupon handling.
    this.couponCodeField = null;
    this.couponSubmit = null;

    if (this.step === 8.0 ||  !this.step) {
        return;
    }

    this.exitAndSaveButton = this.form.querySelector('#exit-and-save');

    if (VuriaUtil.elementExists('.match-height')) {
        Array.prototype.slice.call(this.form.querySelectorAll('.match-height')).forEach(function (element) {
            VuriaUtil.matchHeight(element);
        })
    }

    this.addEventListeners();

    if (this.step === 2.0) {
        new Cleave('#phone-number', {
            phone: true,
            phoneRegionCode: 'us'
        })
    }
    
    if (this.step === 2.5) {
        this.prepareStep2point5();
    }

    if (this.step === 3.0) {
        this.icaLink = this.form.querySelector('.ica-link');
        this.prepareStep3();
    }

    if (this.step === 4.0) {
        this.planPrintouts = document.querySelectorAll('.plan-document');
        this.prepareStep4();
    }

    if (this.step === 5.0) {
        this.prepareStep5();
    }

    if (this.step === 6.0) {
        this.prepareStep6();
    }

    this.form.CompensationForm = this;
};

CompensationForm.prototype.addEventListeners = function () {
    var _this = this;

    this.submitButton.addEventListener('click', _this.submit.bind(_this));

    const formElements = _this.form.querySelectorAll('[name]');
    for (var idx in formElements) {
        var element = formElements[idx];

        if (element instanceof HTMLElement) {
            if (element.tagName.toLowerCase() === 'select') {
                $(element).select2(_this.select2Options)
            }
        }
    }

    if (this.step === 4.0 || this.step === 5.0) {
        this.initialPayment = this.form.querySelector('#selected-payment').value || 200;
        this.initialPaymentDropdown = this.form.querySelector('[name="initial_payment"]');
        this.couponCodeField = this.form.querySelector('[name="coupon_code"]');
        this.couponSubmit = this.form.querySelector('.apply-coupon-btn');

        $(this.initialPaymentDropdown).on('change', function () {
            _this.initialPayment = parseFloat(this.value);
            _this.updateFees();
        });

        this.couponSubmit.addEventListener('click', _this.applyNewCoupon.bind(_this));
    }

    if (this.step === 5.0) {
        this.updatePlan();
    }

    if (this.exitAndSaveButton) {
        this.exitAndSaveButton.addEventListener('click', _this.exitAndSave.bind(_this));
    }
};

CompensationForm.prototype.validate = function () {
    var _this = this;
    this.errors = [];

    const formElements = _this.form.querySelectorAll('[name]');
    for (var idx in formElements) {
        var element = formElements[idx];

        if (element instanceof HTMLElement && element.classList.contains('required')) {
            if (
                !element.value
                ||
                (element.type === 'checkbox' && !element.checked)
            ) {
                element.getParentByClass('form-group').classList.add('form-error');
                if (element.getParentByClass('form-error')) {
                    element.getParentByClass('form-error').querySelector('.error-message').textContent = 'This field is required.';
                }
                _this.error = true;
                _this.errors.push(element);
            } else if (element.type === 'radio') {
                var selected = false;
                var radios = _this.form.querySelectorAll('[name="' + element.name + '"]');
                for (var idx in radios) {
                    var radio = radios[idx];
                    if (radio instanceof HTMLElement) {
                        if (radio.checked) {
                            selected = true;
                        }
                    }
                }


                if (!selected) {
                    element.getParentByClass('form-group').classList.add('form-error');
                    if (element.getParentByClass('form-error')) {
                        element.getParentByClass('form-error').querySelector('.error-message').textContent = 'This field is required.';
                    }
                    _this.error = true;
                    _this.errors.push(element);
                } else {
                    element.getParentByClass('form-group').classList.remove('form-error');
                    if (element.getParentByClass('form-error')) {
                        element.getParentByClass('form-error').querySelector('.error-message').textContent = '';
                    }
                }
            } else {
                if (element.getParentByClass('form-error')) {
                    element.getParentByClass('form-error').querySelector('.error-message').textContent = '';
                    element.getParentByClass('form-error').classList.remove('form-error');
                }
            }
        }
    }

    if (this.step === 5.0 && w9.enabled) {
        var mustSignW9Message = document.querySelector('.must-sign-w9-message');
        if (mustSignW9Message) {
            if (!(w9.hasCompleted || w9.hasSubsequentlyCompleted)) {
                mustSignW9Message.classList.remove('display-none');
                this.errors.push(mustSignW9Message);
            } else {
                mustSignW9Message.classList.add('display-none');
            }
        }
    }

    VuriaUtil.matchHeightUpdate();

    if (!Object.keys(this.errors).length) {
        this.error = false;
    } else {
        this.stopLoading();
        if (this.errors[0]) {
            $('html, body').animate({
                scrollTop: ($(_this.errors[0].parentNode).offset().top - (window.innerHeight / 2))
            }, 400);
            this.errors[0].focus();
        }
    }
};

// REMINDER: this submit function is called on Save and Exit.
CompensationForm.prototype.submit = function (event) {
    this.startLoading();
    var _this = this;

    // Step 2 has recaptcha. The way it works is it will (below) call the recaptcha code. If it succeeds, it'll call
    // back to this method without an event. The way we know that we're in that flow is if there is no event.
    if (event) {
        event.preventDefault();

        if (!this.form.querySelector('[name="update"]')) {
            this.validate();

            if (!this.error && this.total && this.step === 5.0) {
                const formData = this.form.serializeData();
                // Don't use the value in the coupon code field because it might not have been validated. Use the value
                // that's been validated, if any.
                delete formData.coupon_code;
                if (_this.couponCodeField && _this.planData.coupon_code?.code) {
                    formData.coupon_code = _this.planData.coupon_code?.code;
                }
                ;(async () => {
                    try {
                        const res = await new Promise((resolve, reject) => {
                            VuriaUtil.jsonPost(_this.postUrl, formData, (responseData) => {
                                if (responseData.status >= 200 && responseData.status < 400) {
                                    var response = JSON.parse(responseData.responseText);
                                    resolve(response);
                                } else {
                                    reject(responseData);
                                }
                            });
                        });
                        if (!res.success) {
                            _this.handleJsonPayloadResponse(res);
                            return;
                        }
                    } catch (e) {
                        window.alert('An error occurred while posting to the server, before attempting payment');
                        this.stopLoading();
                        return;
                    }

                    // No need to handle the response promise. Stripe will process and redirect. The
                    // submitStripePayment() function will handle errors.
                    _this.submitStripePayment();
                })();
                return;
            }
        }

        if (this.step === 2.0 && Recaptcha && !this.form.querySelector('input[name="g-recaptcha-response"]')) {
            // This will trigger our recaptcha.js file's submitForm, which will end up calling back to this method.
            var event = document.createEvent('Event');
            event.initEvent('submit', true, true, {});
            this.form.dispatchEvent(event);
            return false;
        }
    }

    if (!this.error && !this.errors.length) {
        var formData = this.form.serializeData();
        // Don't use the value in the coupon code field because it might not have been validated. Use the value that's
        // been validated, if any.
        delete formData.coupon_code;
        if (_this.couponCodeField && _this.planData.coupon_code?.code) {
            formData.coupon_code = _this.planData.coupon_code?.code;
        }

        VuriaUtil.jsonPost(_this.postUrl, formData, _this.handleResponse.bind(_this));
    } else {
        this.stopLoading();
    }
};

CompensationForm.prototype.submitStripePayment = async function() {
    const messageContainer = document.querySelector("#payment-message");
    messageContainer.classList.add('hidden');
    const showMessageAndStopLoading = (messageText) => {
        messageContainer.classList.remove('hidden');
        messageContainer.textContent = messageText;
        this.stopLoading();
    }

    // What does stripePaymentElements.submit do? Stripe's sample code says "Trigger form validation and wallet
    // collection".
    const { error: stripeElementsSubmitError } = await this.stripePaymentElements.submit();
    if (stripeElementsSubmitError) {
        showMessageAndStopLoading(stripeElementsSubmitError.message);
        return;
    }
    let result;
    try {
        const totalForStripe = this.getTotalForStripe();
        result = await VuriaUtil.jsonFetch2(window.WestUsaStripeData.api_url, {
            body: JSON.stringify({
                total_for_stripe: totalForStripe,
                coupon_code: this.planData.coupon_code?.code,
                initial_payment: parseFloat(this.initialPaymentDropdown.value),
            }),
        });
    } catch (e) {
        showMessageAndStopLoading(e.message);
        return;
    }
    const { success, message, data } = result;
    if (!success) {
        showMessageAndStopLoading(message);
        return;
    }
    const { clientSecret } = data;
    try {
        result = await this.stripe.confirmPayment({
            clientSecret,
            elements: this.stripePaymentElements,
            confirmParams: {
                return_url: window.location.href,
            },
        });
    } catch (e) {
        showMessageAndStopLoading(e.message);
        return;
    }
    const { error } = result;
    if (error) {
        if (error.type === 'card_error' || error.type === 'validation_error') {
            showMessageAndStopLoading(error.message);
        } else {
            showMessageAndStopLoading('An unexpected error occurred during payment');
        }
    }

    // This function is async so that we can use the await keyword, but the return value is not expected to be used.
    return 'This return value is not expected to be used';
}

CompensationForm.prototype.handleResponse = function (responseData) {
    var _this = this;

    function maybeResetCaptcha() {
        if (Recaptcha) {
            grecaptcha.reset();
            var node = _this.form.querySelector('input[name="g-recaptcha-response"]');
            if (node) {
                _this.form.removeChild(node);
            }
        }
    }

    if (responseData.status >= 200 && responseData.status < 400) {
        var response = JSON.parse(responseData.responseText);
        _this.handleJsonPayloadResponse(response);
    } else {
        maybeResetCaptcha();
        alert('An error occurred trying to submit your data.')
        this.stopLoading();
    }

    this.stopLoading();
};

CompensationForm.prototype.handleJsonPayloadResponse = function (response) {
    var _this = this;

    if (!response.success) {
        var element = null;
        var isElementVisible = false;
        for (var key in response.errors) {
            var message = response.errors[key];
            element = _this.form.querySelector('[name="' + key + '"]');
            isElementVisible = element && element.type !== 'hidden';

            if (isElementVisible) {
                element.parentNode.classList.add('form-error');
                element.nextElementSibling.innerHTML = message;
            } else {
                alert(message);
            }
        }
        maybeResetCaptcha();

        if (!!element && isElementVisible) {
            $('html, body').animate({
                scrollTop: ($(element).offset().top - (window.innerHeight / 2))
            }, 400);
            element.focus();
        }
        this.stopLoading();
    } else {
        // As mentioned on Asana case 1208481934277889, I think there's a change (as of 2024-09-30-ish) with
        // DocuSign behavior where, when we navigate the user from step 5 to step 6, the DocuSign iFrame warns the
        // user that their changes are unsaved (even when the user has finished the signing process). The user may
        // choose "Cancel" or "Leave". Leave is actually what we want, as they'll move on to step 6. But it's a
        // scary/confusing warning to users, so it's understandable that they'd choose Cancel and try to click
        // Next again. The solution is to remove the iFrame.
        //
        // No need to check if this is step 5 or if the iframe is showing, as this is harmless otherwise.
        $('.w9-embedded-form').find('iframe').remove();
        window.location.href = response.url;
    }
}

CompensationForm.prototype.exitAndSave = function (event) {
    var input = document.createElement('input');
    input.type = 'hidden';
    input.value = 1;
    input.name = 'update';

    this.form.appendChild(input);
    this.submit(event);
}

CompensationForm.prototype.startLoading = function () {
    var _this = this;
    this.submitButton.emptyNode();

    var icon = document.createElement('i');
    icon.classList.add('fa', 'fa-spinner', 'fa-spin');
    this.submitButton.appendChild(icon);
    this.submitButton.disabled = true;
}

CompensationForm.prototype.stopLoading = function () {
    var _this = this;
    this.submitButton.emptyNode();

    var icon = document.createElement('i');
    icon.classList.add('fa', 'fa-angle-right');
    this.submitButton.textContent = 'Next';
    this.submitButton.appendChild(icon);
    this.submitButton.disabled = false;
}

CompensationForm.prototype.applyNewCoupon = function (event) {
    var _this = this;
    event.preventDefault();
    this.startLoadingCoupon();
    VuriaUtil.jsonPost(_this.couponSubmit.dataset.action, this.form.serializeData(), this.couponResponse.bind(this));
}

CompensationForm.prototype.couponResponse = function (responseData) {
    var _this = this;

    if (responseData.status >= 200 && responseData.status < 400) {
        var response = JSON.parse(responseData.responseText);
        if (response.success) {
            _this.planData = response.data;
            if (_this.step === 4.0) {
                _this.monthlyCost = parseFloat(_this.planSelector.get());
            }
            _this.updatePlan();
        } else {
            alert(response.message);
            if (!response.success) {
                this.error = true;
                this.couponCodeField.value = '';
            }
        }
    } else {
        alert('An error occurred while talking to the server.');
    }

    this.stopLoadingCoupon();
}

CompensationForm.prototype.startLoadingCoupon = function () {
    var _this = this;
    var icon = document.createElement('i');
    icon.classList.add('fa', 'fa-spinner', 'fa-spin');

    this.couponSubmit.innerHTML = '';
    this.couponSubmit.appendChild(icon);
    this.couponSubmit.disabled = true;
}

CompensationForm.prototype.stopLoadingCoupon = function () {
    var _this = this;

    this.couponSubmit.innerHTML = '';
    this.couponSubmit.textContent = 'Apply';
    this.couponSubmit.disabled = false;
}

CompensationForm.prototype.prepareStep2point5 = function () {
    var _this = this;

    var indemnificationLink = this.form.querySelector('.indemnification-link').querySelector('.indemnification-link');
    var indemnificationSubmit;
    var modal;

    indemnificationLink.addEventListener('click', function (event) {
        event.preventDefault();
        var modalContent = _this.form.querySelector('.indemnification-modal').cloneNode(true).innerHTML;
        modal = bootbox.dialog({
            title: '',
            message: modalContent,
        }).off("shown.bs.modal").init(function() {
            $('.modal-dialog').addClass('ica-modal');
        });

        var indemnificationFields = Array.prototype.slice.call(document.querySelector('.ica-modal').querySelectorAll('input'));
        indemnificationSubmit = document.querySelector('.ica-modal')
            .querySelector('#indemnification-submit');
        var indemnificationDontAgreeBtn = document.querySelector('.ica-modal')
            .querySelector('#indemnification-dont-agree');

        indemnificationSubmit.addEventListener('click', function (event) {
            var errors = [];
            event.preventDefault();
            indemnificationFields.forEach(function (element) {
                if (element.type !== 'submit') {
                    if (!element.value) {
                        element.parentNode.classList.add('form-error');
                        element.nextElementSibling.textContent = 'This field is required.';
                        errors.push(element);
                    } else {
                        element.parentNode.classList.remove('form-error');
                        element.nextElementSibling.textContent = '';
                    }
                }
            })

            if (!errors.length) {
                indemnificationFields.forEach(function (element) {
                    if (element.type !== 'submit') {
                        var value = element.value;
                        element = _this.form.querySelector('[name="' + element.name + '"]');
                        element.value = value;
                    }
                })

                _this.form.querySelector('[name="confirm"]').value = 1;
                indemnificationLink.classList.add('signed')
                indemnificationLink.textContent = 'Indemnification Signed';

                $('button.bootbox-close-button').trigger('click')
            }
        });
        indemnificationDontAgreeBtn.addEventListener('click', function (event) {
            _this.form.querySelector('[name="indemnification_form_name"]').value = '';
            _this.form.querySelector('[name="confirm"]').value = '';
            indemnificationLink.classList.remove('signed')
            indemnificationLink.textContent = 'Click Here to eSign the Indemnification Form';

            indemnificationLink.textContent = 'Click Here to eSign the Indemnification Form';

            $('button.bootbox-close-button').trigger('click')
        });
    });

    // Be advised: brokerageName could be undefined
    function showHideIndemnification(brokerageName) {
        const $signatureSection = $('.indemnification-signature-section');
        const $spotHolder = $('.indemnification-signature-section-spot-holder');
        const $compensationFormRemovedSection = $('.compensation-form-removed');

        if (brokerageName === 'west usa choice') {
            $signatureSection.insertAfter($spotHolder);
        } else {
            $signatureSection.appendTo($compensationFormRemovedSection);
        }
    }

    var brokerages = _this.form.querySelectorAll('[name="brokerage"]');
    Array.prototype.slice.call(brokerages).forEach(function (brokerage) {
        brokerage.addEventListener('change', function (event) {
            var element = event.target;
            var brokerageName = element.parentNode.textContent.toLowerCase();
            showHideIndemnification(brokerageName);
        });
    });
    const initiallySelectedBrokerageElement = _this.form.querySelector('[name="brokerage"]:checked');
    showHideIndemnification(initiallySelectedBrokerageElement?.value.toLowerCase());
}

CompensationForm.prototype.prepareStep3 = function () {
    var _this = this;
    var icaForm = document.querySelector('.ica-modal').cloneNode(true).innerHTML;
    var modal;

    new Cleave('#birth-date', {
        date: true,
        delimiter: '/',
        datePattern: ['m', 'd', 'Y']
    });

    new Cleave('#home-phone', {
        phone: true,
        phoneRegionCode: 'us',
    })

    new Cleave('#mobile-phone', {
        phone: true,
        phoneRegionCode: 'us',
    })

    this.icaLink.addEventListener('click', function (event) {
        event.preventDefault();
        modal = bootbox.dialog({
            title: '',
            message: icaForm
        }).off("shown.bs.modal").init(function () {
            $('.modal-dialog').addClass('ica-modal');
        });

        var inputs = Array.prototype.slice.call(document.querySelector('.modal-body').querySelectorAll('input'));
        var submit = document.querySelector('.modal-body').querySelector('input[value="submit"]');

        submit.addEventListener('click', function (event) {
            event.preventDefault();
            var errors = [];

            inputs.forEach(function (element) {
                if (element.type !== 'submit') {
                    if (!element.value && element.classList.contains('required')) {
                        element.parentNode.classList.add('form-error');
                        if (element.parentNode.querySelector('.error-message')) {
                            element.parentNode.querySelector('.error-message').textContent = 'This field is required.';
                        }
                        errors.push(element);
                    } else {
                        element.parentNode.classList.remove('form-error');
                        if (element.parentNode.querySelector('.error-message')) {
                            element.parentNode.querySelector('.error-message').textContent = '';
                        }
                    }
                }
            })

            if (!errors.length) {
                inputs.forEach(function (element) {
                    if (element.type !== 'submit') {
                        var value = element.value;
                        element = _this.form.querySelector('[name="' + element.name + '"]');
                        element.value = value;
                    }
                })
                $('button.bootbox-close-button').trigger('click')
                _this.form.querySelector('[name="contractor_agreement"]').checked = true;
            } else {
                errors[0].focus();
            }

        })
    })

    var boardMemberships = this.form.querySelectorAll('[name="board_membership_form"]');
    Array.prototype.slice.call(boardMemberships).forEach(function (boardMembership) {
        boardMembership.addEventListener('change', function (event) {
            var element = event.target;
            if (element.parentNode.textContent.toLowerCase() === 'yes') {
                _this.form.querySelector('.board-membership-dropdown').style.display = 'block';
                _this.form.querySelector('.board-membership-links-dropdown').style.display = 'none';
                _this.form.querySelector('.board-membership-join-hint').style.display = 'none';
            } else if (element.parentNode.textContent.toLowerCase() === 'no') {
                _this.form.querySelector('.board-membership-dropdown').style.display = 'block';
                _this.form.querySelector('.board-membership-links-dropdown').style.display = 'block';
                _this.form.querySelector('.board-membership-join-hint').style.display = 'block';
            } else if (element.parentNode.textContent.toLowerCase() === "haven't joined") {
                _this.form.querySelector('.board-membership-dropdown').style.display = 'none';
                _this.form.querySelector('.board-membership-links-dropdown').style.display = 'none';
                _this.form.querySelector('.board-membership-join-hint').style.display = 'none';
            }
        })
    });
}

CompensationForm.prototype.prepareStep4 = function () {
    var _this = this;
    var plans = Object.values(_this.planData.compensation_plans).reverse();
    var possibleValues = plans.map(function (x) { return parseInt(x.monthly_cost); });
    var min = possibleValues[0];
    var max = possibleValues[possibleValues.length - 1];
    var initialValue = this.monthlyCost;
    // This is for the slider on hire step 4.
    // Reminder: we mutate this range below. The most important thing to remember is that the range keys must be
    // percentages, with '%' at the end.
    var range = {
        min: min,
        max: max,
    };
    possibleValues.forEach(function(x, i) {
        var percent = Math.round(i / (possibleValues.length - 1) * 100);
        range[percent + '%'] = x;
    });

    this.planSelector = noUiSlider.create(document.getElementById('change-plan-slider'), {
        start: [initialValue],
        behaviour: 'tap-drag',
        range: range,
        format: wNumb({
            decimals: 0
        }),
        pips: {
            mode: 'values',
            values: possibleValues,
            density: 100,
            format: wNumb({
                prefix: '$',
                decimals: 0
            })
        },
        snap: true,
    });

    window.planSelector = this.planSelector;
    this.planSelector.on('change', function (values, handle) {
        var val = parseFloat(values[handle]);
        // Note 972498572:
        // I set the min on the range to be min (25 right now. Importantly, not 0) because I did not want a pip to be
        // drawn at 0. Therefore the min value we'll receive here is 25. But if the slider handle was dragged, it might
        // appear as 0. So we force it to the min either way to handle.
        if (val <= min) {
            window.planSelector.set(min);
        }
    });
    this.planSelector.on('update', function (values, handle) {
        if (parseFloat(values[handle]) < min) {
            return;
        }
        _this.monthlyCost = parseFloat(values[handle]);
        _this.updatePlan();
    })
};

CompensationForm.prototype.prepareStep5 = function () {
    var _this = this;
    var planLink = this.form.querySelector('.plan-link').querySelector('.plan-link');
    var planSubmit;
    var modal;

    planLink.addEventListener('click', function (event) {
        event.preventDefault();
        var modalContent = _this.form.querySelector('.plan-modal').cloneNode(true).innerHTML;
        modal = bootbox.dialog({
            title: '',
            message: modalContent,
        }).off("shown.bs.modal").init(function() {
            $('.modal-dialog').addClass('ica-modal');
        });


        var planFields = Array.prototype.slice.call(document.querySelector('.ica-modal').querySelectorAll('input'));
        planSubmit = document.querySelector('.ica-modal').querySelector('#plan-submit');

        planSubmit.addEventListener('click', function (event) {
            var errors = [];
            event.preventDefault();
            planFields.forEach(function (element) {
                if (element.type !== 'submit') {
                    if (!element.value) {
                        element.parentNode.classList.add('form-error');
                        element.nextElementSibling.textContent = 'This field is required.';
                        errors.push(element);
                    } else {
                        element.parentNode.classList.remove('form-error');
                        element.nextElementSibling.textContent = '';
                    }
                }
            })

            if (!errors.length) {
                planFields.forEach(function (element) {
                    if (element.type !== 'submit') {
                        var value = element.value;
                        element = _this.form.querySelector('[name="' + element.name + '"]');
                        element.value = value;
                    }
                })

                _this.form.querySelector('[name="confirm"]').value = 1;
                planLink.classList.add('signed')
                planLink.textContent = 'Plan Signed';

                $('button.bootbox-close-button').trigger('click')
            }
        })
    })

    var paymentSelect = document.getElementById('initial-payment');
    if (!!paymentSelect) {
        $(paymentSelect).trigger('change');
    }

    if (w9.enabled && !w9.hasCompleted) {
        var $wrapper = $('#checkout-form');
        $wrapper.addClass('wusa-disabled');
        var $startButton = $('.w9-start-button');
        var $verifyLink = $('.w9-verify-link');
        var $verifyButton = $('.w9-verify-button');
        var $mustSignW9Message = $('.must-sign-w9-message');
        $startButton.on('click', function() {
            $startButton.hide();
            $verifyLink.hide();
            var iFrameNode = document.createElement('iframe');
            iFrameNode.src = w9.iFrameUrl;
            $('.w9-embedded-form').append(iFrameNode);
            $verifyButton.show();
        });
        function verify() {
            $('.w9-verify-response .verified').hide();
            $('.w9-verify-response .unverified').hide();
            $('.w9-verify-response .error').hide();
            $mustSignW9Message.hide();
            var $waitSpinners = $('.w9 .wait-spinner');
            var $buttonArrows = $('.w9 .button-arrow');
            $buttonArrows.hide();
            $waitSpinners.show();
            // Use inline-block, or fa-spin won't have an effect.
            $waitSpinners.css('display', 'inline-block');
            $waitSpinners.addClass('fa-spin');
            $.ajax({
                dataType: 'json',
                url: w9.apiUrl,
                method: 'POST',
                complete: function() {
                    $buttonArrows.show();
                    $waitSpinners.hide();
                    $waitSpinners.removeClass('fa-spin');
                },
                error: function(error) {
                    var $error = $('.w9-verify-response .error');
                    $error.show();
                    var message = error.message;
                    if (error.responseJSON && error.responseJSON.errors && error.responseJSON.errors.length) {
                        message = error.responseJSON.errors[0];
                    }
                    $error.text(message);
                },
                success: function(data) {
                    if (data.hasCompleted) {
                        $startButton.hide();
                        $verifyLink.hide();
                        $verifyButton.hide();
                        $('.w9-verify-response .verified').show();
                        $wrapper.removeClass('wusa-disabled');
                        w9.hasSubsequentlyCompleted = true;
                    } else {
                        $('.w9-verify-response .unverified').show();
                    }
                },
            });
        }
        $verifyButton.on('click', verify);
        $verifyLink.on('click', verify);
    }
}

CompensationForm.prototype.prepareStep6 = function () {
    var _this = this;
    var branchContainer = this.form.querySelector('.branch-entity-codes');
    var checkboxes = branchContainer.querySelectorAll('.checkbox');
    checkboxes = Array.prototype.slice.call(checkboxes);

    checkboxes.forEach(function (element) {
        element.addEventListener('click', _this.branchCheckbox.bind(_this));
    })

    if (this.form.querySelector('#branch').value) {
        var value = this.form.querySelector('#').value;
        var element = this.form.querySelector('[data-branch="' + value + '"]');
        var event = document.createEvent('Event');
        event.initEvent('click', true, true, {});
        element.dispatchEvent(event);
    }

    var branches = branchContainer.querySelectorAll('.branch-entity');
    for (var idx in branches) {
        var anchorPoint = branches[idx];
        if (anchorPoint instanceof HTMLElement) {
            var id = anchorPoint.dataset.branch;
            var tooltipContent = _this.form.querySelector('#' + id).innerHTML;
            var mapTrigger = anchorPoint.querySelector('.map')
            $(mapTrigger).tooltipster({
                trigger: 'click',
                content: tooltipContent.toNode(),
                contentAsHTML: true,
                interactive: true,
                theme: 'tooltipster-shadow',
            });
        }
    }

    var haveNotTransferredInputs = this.form.querySelectorAll('input[name="license_transfer"]');
    var azDreLink = this.form.querySelector('.az-dre-link');
    haveNotTransferredInputs.forEach(function(input) {
        input.addEventListener('change', function(event) {
            var shouldShow = event.target.value.startsWith('I have not');
            if (shouldShow) {
                azDreLink.classList.remove('hide');
            } else {
                azDreLink.classList.add('hide');
            }
        });
    })
}

CompensationForm.prototype.branchCheckbox = function (event) {
    var _this = this;
    var element = event.target;
    var checkboxes = this.form.querySelector('.branch-entity-codes').querySelectorAll('.checkbox');
    checkboxes = Array.prototype.slice.call(checkboxes);

    checkboxes.forEach(function (element) {
        element.classList.remove('checked');
    })

    element.classList.add('checked');
    this.form.querySelector('#branch').value = element.dataset.branch;
}

CompensationForm.prototype.updatePlan = function () {
    var _this = this;

    _this.selectedPlan = Object.values(_this.planData.compensation_plans).find(function(x) {
        return _this.monthlyCost === parseFloat(x.monthly_cost);
    });

    if (this.selectedPlan !== this.previousPlan) {
        this.previousPlan = this.selectedPlan;

        $(this.initialPaymentDropdown).select2('destroy');
        this.initialPaymentDropdown.options.length = 0;

        for (var idx in this.selectedPlan.initial_payments) {
            var option = _this.selectedPlan.initial_payments[idx];
            var selected = (parseFloat(option.payment_amount) === parseFloat(this.initialPayment));
            var optionObj = new Option(parseFloat(option.payment_amount).formatMoney(2), option.payment_amount, selected, selected);
            _this.initialPaymentDropdown.appendChild(optionObj);
        }

        $(this.initialPaymentDropdown).select2(_this.select2Options);

        if (this.step === 4.0) {
            Array.prototype.slice.call(this.planPrintouts).forEach(function (printout) {
                if (printout.dataset.plan.toLowerCase() === _this.selectedPlan.letter.toLowerCase()) {
                    printout.style.display = 'block';
                } else {
                    printout.style.display = 'none';
                }
            });
        }
    }

    if (this.step === 4.0) {
        var planDocumentNode = Array.prototype.find.call(_this.planPrintouts, function(planDocument) {
            return planDocument.dataset.plan === _this.selectedPlan.letter;
        });
        $('.view-details').attr('href', $(planDocumentNode).attr('href'));
    }

    this.updateFees();
};

CompensationForm.prototype.updateFees = function () {
    var _this = this;

    var monthlyFeeDiscounts = [1, 4, 5, 7, 8];
    if (this.planData.coupon_code && Object.keys(_this.planData.coupon_code).length) {
        if (monthlyFeeDiscounts.indexOf(parseInt(_this.planData.coupon_code.discount_type)) > -1) {
            this.monthlyFee = (parseInt(this.monthlyCost) * 0.01 * (100 - _this.planData.coupon_code.percentage_discount));
        } else {
            this.monthlyFee = this.monthlyCost;
        }
    } else {
        this.monthlyFee = this.monthlyCost;
    }

    this.form.querySelector('.monthly-fee').textContent = this.monthlyFee.formatMoney(2);
    this.form.querySelector('#monthly-fee').value = this.monthlyFee;
    this.form.querySelector('#monthly-cost').value = this.monthlyCost;
    this.form.querySelector('#annual-fee').value = this.selectedPlan.annual_fee.formatMoney(0);

    this.form.querySelector('.setup-fee').textContent = parseFloat(this.selectedPlan.setup_fee).formatMoney(2);
    this.form.querySelector('#setup-fee').value = this.selectedPlan.setup_fee;

    this.form.querySelector('.annual-fee').textContent = parseFloat(this.selectedPlan.annual_fee).formatMoney(2);
    this.form.querySelector('#annual-fee').value = this.selectedPlan.annual_fee;

    if (this.step === 4.0) {
        this.form.querySelector('.selected-plan-letter').textContent = this.selectedPlan.plan_name;
        this.form.querySelector('.current-monthly-cost').querySelector('h2').textContent = this.monthlyCost.formatMoney(0);
        this.form.querySelector('.annual-fee').textContent = this.selectedPlan.annual_fee.formatMoney(2);
    }

    if (this.step === 4.0 || this.step === 5.0) {
        this.form.querySelector('#selected-payment').value = this.initialPayment;
    }

    this.total = this.calculateTotal();
    this.maybeShowPaymentForm();

    this.form.querySelector('#total').value = this.total;
    this.form.querySelector('#total-due').textContent = this.total.formatMoney(2);

    this.updateCallouts();
}

CompensationForm.prototype.maybeShowPaymentForm = function() {
    if (this.step !== 5.0) {
        return;
    }

    if (this.total) {
        if (!this.stripe) {
            this.stripe = Stripe(WestUsaStripeData.publishable_api_key);
            const options = {
                mode: 'payment',
                // This amount is temporary/fake. Let's pick a number that's semi recognizable just in case.
                // This is one hundred and eleven cents.
                amount: 111,
                currency: 'usd',
            };
            this.stripePaymentElements = this.stripe.elements(options);
            const paymentElementOptions = {
                layout: 'accordion',
            };
            const paymentElement = this.stripePaymentElements.create('payment', paymentElementOptions);
            paymentElement.mount('#payment-element');

            $('#comp-no-cost').hide();
            $('#payment-element').show();
        } else {
            this.stripePaymentElements.update({
                amount: this.getTotalForStripe(),
            });
        }
    } else {
        $('#payment-element').hide();
        $('#comp-no-cost').show();
    }
}

CompensationForm.prototype.updateCallouts = function () {
    var _this = this;

    var yearlyCapStr = this.selectedPlan.yearly_cap
        ? VuriaUtil.displayAsDollars(this.selectedPlan.yearly_cap)
        : 'None';

    document.querySelector('.transaction-fee').querySelector('h1').textContent = this.planData.transaction_fee.formatMoney(0);
    document.querySelector('.monthly-cost').querySelector('h1').textContent = this.monthlyCost.formatMoney(0);
    document.querySelector('.yearly-cap').querySelector('h1').textContent = yearlyCapStr;

    var initialPaymentMessage = this.form.querySelector('#initial-payment-message');
    if (this.initialPayment < 200) {
        initialPaymentMessage.style.display = 'block';
    } else {
        initialPaymentMessage.style.display = 'none';
    }
}

CompensationForm.prototype.getTotalForStripe = function () {
    return this.total * 100;
}

CompensationForm.prototype.calculateTotal = function () {
    var monthly = parseFloat(this.monthlyFee);
    var initial = parseFloat(this.initialPaymentDropdown.value);
    var setupFee = parseFloat(this.selectedPlan.setup_fee);
    var annualFee = parseFloat(this.selectedPlan.annual_fee);

    return monthly + initial + setupFee + annualFee;
}

if (VuriaUtil.elementExists('#compensation-form')) {
    window.test = new CompensationForm();
}
